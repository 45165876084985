.MainPage_div1 {
  display: flex;
  flex-direction: column;
}
.MainPage_header {
  margin-bottom: 22px;
}
.MainPage_div2 {
  margin-top: 22px;
  background-color: #2c2c2c;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}
.MainPage_p1 {
  padding: 12px;
  align-content: center;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.MainPage_servicesSummary {
  margin-top: 22px;
}
.MainPage_testimonial {
  margin-top: 12px;
}
.MainPage_whyChooseUs {
  margin-top: 12px;
}
.MainPage_div3 {
  background-color: black;
  color: #fff;
  margin-top: 12px;
  text-align: center;
  font-size: 30px;
  font-weight: 600;
  padding: 10px;
}
.MainPage_button1 {
  background-color: #800020;
  padding-top: 6px;
  padding-right: 12px;
  padding-bottom: 6px;
  padding-left: 12px;
  border-radius: 12px;
  width: 60%;
}
.MainPage_div4 {
  margin-bottom: 20px;
}

.Footer_div1 {
  background-color: #800020;
  color: #fff;
  text-align: center;
  padding: 12px;
  font-size: 14px;
}
.Footer_a1 {
  font-size: 14px;
}

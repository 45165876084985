.AboutUsPage_div1 {
  background-color: #2c2c2c;
  color: #fff;
  text-align: center;
  font-size: 26px;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  padding: 8px;
  border-radius: 14px;
  align-items: center;
}
.AboutUsPage_div2 {
  text-align: center;
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 22px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  max-width: 80%;
}
.AboutUsPage_p1 {
  font-size: 16px;
  margin-top: 8px;
}
.AboutUsPage_p2 {
  font-size: 18px;
  margin-bottom: 22px;
  max-width: 80%;
}
.aboutistitle {
}
.AboutUsPage_hr1 {
  margin-top: 4px;
  margin-bottom: 8px;
}

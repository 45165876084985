.HeroMain {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
}
.Hero_div1 {
  display: flex;
  align-items: center;
  background-color: #171717;
  width: 100%;
  color: #ffffff;
  justify-content: center;
  padding: 12px;
  border-radius: 16px;
}
.Hero_div2 {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-around;
  background-color: #171717;
}
.Hero_img1 {
  max-width: 22%;
  border-radius: 40%;
  border: 2px solid #800020;
}
.Hero_img2 {
  max-width: 22%;
  border-radius: 40%;
  border: 2px solid #800020;
}
.Hero_img3 {
  max-width: 22%;
  border-radius: 40%;
  border: 2px solid #800020;
}
.Hero_img4 {
  max-width: 22%;
  border-radius: 40%;
  border: 2px solid #800020;
}
.Hero_div3 {
  font-size: 22px;
  font-weight: 700;
}
.Hero_div4 {
  font-size: 22px;
  font-weight: 700;
}
.Hero_div5 {
  color: #800020;
}
.Hero_div6 {
  color: #800020;
}
.Hero_div7 {
  color: #800020;
}
.Hero_div8 {
  color: #800020;
}

.WhyChooseUs_div1 {
  background-color: #171717;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 24px;
  padding: 8px;
}
.WhyChooseUs_grid {
  display: grid;
  grid-template: 1fr 1fr / 1fr 1fr;
  gap: 20px;
}
.whychoosebox {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
}
.WhyChooseUs_div2 {
  font-size: 20px;
}
.WhyChooseUs_p1 {
  font-size: 16px;
  text-align: center;
}
.WhyChooseUs_div3 {
  color: #800020;
}

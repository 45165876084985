/* Hide menu and display hamburger on mobile */
.HeaderMenu {
  display: flex;
  align-items: center;
  font-size: 16px;
}

.HeaderMenu.mobile-hidden {
  display: none;
}

.HamburgerIcon {
  display: none;
  font-size: 24px;
  cursor: pointer;
}

.Drawer {
  position: fixed;
  top: 0;
  right: 0;
  width: 250px;
  height: 100%;
  background-color: #800020;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  align-items: flex-start;
}

.Drawer a {
  margin: 10px 20px;
  font-size: 18px;
}

/* Mobile view */
@media (max-width: 768px) {
  .HeaderMenu {
    display: none;
  }

  .HamburgerIcon {
    display: block;
  }
}
.Header_div1 {
  background-color: #800020;
  color: #fff;
  display: flex;
  align-content: center;
  justify-content: center;
  width: 100%;
}
.Header_div2 {
  display: flex;
  justify-content: space-between;
  padding: 12px;
  max-width: 1200px;
  width: 100%;
  font-size: 22px;
}
.headertitlediv {
  font-size: 22px;
}
.Header_a1 {
  margin-right: 12px;
}

.Layout_div1 {
  width: 100%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  height: 100vh;
}
.mainContainer {
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  max-width: 1200px;
  padding-top: 80px;
  padding-bottom: 60px;
}
.Layout_header {
  margin-bottom: 12px;
}

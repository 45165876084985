.Zloginpage_div1 {
  background-color: #171717;
  color: #fff;
  text-align: center;
  padding: 12px;
}
.Zloginpage_div2 {
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
}
.Zloginpage_input1 {
  width: 400px;
}
.Zloginpage_input2 {
  width: 400px;
}
.Zloginpage_button1 {
  background-color: #800020;
  width: 200px;
}

.Testimonial_div1 {
  background-color: #2c2c2c;
  color: #fff;
  border-radius: 16px;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
.Testimonial_img1 {
  border-radius: 50%;
  width: 200px;
  height: 200px;
}
.Testimonial_div2 {
  padding: 10px;
  width: 200px;
}
.Testimonial_div3 {
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

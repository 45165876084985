.ServicesSummary_div1 {
  background-color: #171717;
  color: #cf2626;
  text-align: center;
  font-size: 22px;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
  padding: 8px;
}
.ServicesSummary_div2 {
  background-color: #2c2c2c;
  margin-top: 12px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  width: 80%;
  margin-bottom: 12px;
  color: #fafafa;
  font-size: 18px;
}
.ServicesSummary_p1 {
  margin-top: 8px;
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 400;
}
.ServiceSummaryTitle {
  font-weight: 600;
  padding: 8px;
}
